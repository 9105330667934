import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import "./header.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import swal from "sweetalert";
import PopupMenu from "./PopupMenu";

const Header = ({ profileProp }) => {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const location = useLocation();
    const [profile, setProfile] = useState(profileProp);
    useEffect(() => {
        setProfile(profileProp);
    }, [profileProp]);
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [isOpen, setIsOpen] = useState(false);
    const [menu, setMenu] = useState(null);
    const openPopup = (num) => {
        setMenu(num);
        setIsOpen(true);
    };
    const closePopup = () => {
        setIsOpen(false);
    };
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
                setSetting(res.data.data[0]);
            });
        const timer = setInterval(() => {
            setProfile(JSON.parse(localStorage.getItem("profile")));
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);
    /*useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/casino/create`);
    }, [profile]);
    useEffect(() => {
        const interval = setInterval(() => {
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                .then((res) => {
                    if (profile != res.data.data) {
                        setProfile(res.data.data);
                        localStorage.setItem("profile", JSON.stringify(res.data.data));
                    }
                })
                .catch((err) => localStorage.removeItem("user"));
        }, 5000);
        return () => clearInterval(interval);
    }, []);*/
    const onSubmit = async (data) => {
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6) {
            swal("Thông báo", "Tên đăng nhập tối thiểu 6 kí tự", "warning");
            return;
        }
        if (!regex.test(data.username)) {
            swal("Thông báo", "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt", "warning");
            return;
        }
        if (data.password.length < 6) {
            swal("Thông báo", "Mật khẩu tối thiểu 6 kí tự", "warning");
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                username: data.username.toLowerCase(),
                password: data.password
            })
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    buttons: "Đồng ý"
                }).then(() => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("profile");
                    localStorage.removeItem("data");
                    localStorage.setItem("user", res.data.data);
                    window.location.href = "/";
                });
            })
            .catch((err) => swal("Thông báo", err.response.data.message ? err.response.data.message : "Tên đăng nhập hoặc mật khẩu không chính xác!", "error"));
    };
    const data = [
        {
            title: "Trang Chủ",
            icon: "nav-icon-home",
            active: "nav-icon-home-active",
            to: "/"
        },
        {
            title: "Casino",
            icon: "nav-icon-casino",
            active: "nav-icon-casino-active",
            to: "/casino"
        },
        {
            title: "TDG",
            icon: "nav-icon-games",
            active: "nav-icon-games-active",
            to: "/sicbo"
        },
        {
            title: "Bắn Cá",
            icon: "nav-icon-fishing",
            active: "nav-icon-fishing-active",
            to: "/fishing"
        },
        {
            title: "Xổ Số",
            icon: "nav-icon-lottery",
            active: "nav-icon-lottery-active",
            to: "/lottery"
        },
        {
            title: "Game Bài",
            icon: "nav-icon-pvp",
            active: "nav-icon-pvp-active",
            to: "/pvp"
        },
        {
            title: "Nổ Hũ",
            icon: "nav-icon-slots",
            active: "nav-icon-slots-active",
            to: "/slots"
        },
        {
            title: "Thể Thao",
            icon: "nav-icon-sports",
            active: "nav-icon-sports-active",
            to: "/sports"
        },
        {
            title: "Esports",
            icon: "nav-icon-esports",
            active: "nav-icon-esports-active",
            to: "/esports"
        },
        {
            title: "Đá Gà",
            icon: "nav-icon-cockfighting",
            active: "nav-icon-cockfighting-active",
            to: "/cockfighting"
        },
        {
            title: "Khuyến mãi",
            icon: "nav-icon-promo",
            active: "nav-icon-promo-active",
            to: "/promotion"
        }
    ];
    const logOut = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("profile");
        localStorage.removeItem("data");
        localStorage.removeItem("data1");
        var now = new Date();
        var time = now.getTime();
        var expireTime = time;
        now.setTime(expireTime);
        document.cookie = "jwt=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.href = "/";
    };
    const getProfile = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    };
    return (
        <>
            <header>
                <div className="header">
                    <div className="container">
                        <div className="main_header d-flex">
                            <div className="logo">
                                <Link to="/">
                                    <img alt="" src={require("../../image/logo.png")} />
                                </Link>
                            </div>
                            <div className="menu_login">
                                {profile ? (
                                    <>
                                        <div className="menu-login">
                                            <ul>
                                                <li>
                                                    Số dư: <span style={{ fontWeight: "bold" }}>{Math.floor(profile?.money).toLocaleString("vi-VN")}</span>
                                                </li>
                                                <li onClick={() => openPopup(1)} className="item">
                                                    <span>Tài khoản</span>
                                                </li>
                                                {/*<li onClick={() => navigate("/invite")} className="item">
                                                    <span>Đại lý</span>
                                                </li>*/}
                                                <li onClick={() => openPopup(2)} className="item">
                                                    <span>Nạp tiền</span>
                                                </li>
                                                <li onClick={() => openPopup(3)} className="item">
                                                    <span>Rút tiền</span>
                                                </li>
                                                <li onClick={() => openPopup(7)} className="item">
                                                    <span>Quy đổi</span>
                                                </li>
                                                <li onClick={() => openPopup(4)} className="item">
                                                    <span>Lịch sử trò chơi</span>
                                                </li>
                                                <li onClick={logOut} className="item">
                                                    <span>Đăng xuất</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="box-login">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="formlogin d-flex">
                                                    <div className="form-group input-username">
                                                        <input type="text" {...register("username")} placeholder="Tên đăng nhập" />
                                                    </div>
                                                    <div className="form-group input-password">
                                                        <input type="password" {...register("password")} placeholder="Mật khẩu" />
                                                    </div>
                                                    <div>
                                                        <button type="submit">Đăng nhập</button>
                                                    </div>
                                                    <div>
                                                        <Link to="/register" className="btn">
                                                            Đăng ký
                                                        </Link>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main_menu">
                    <div className="container d-flex">
                        <nav>
                            <ul>
                                {data.map((item, index) => (
                                    <li className={location.pathname === item.to ? "menu_item active" : "menu_item"} key={index}>
                                        {index == 11 || index == 12 ? (
                                            <a href={item.to} target="_blank">
                                                <div className={`item_nav_icon ${item.icon}`}></div>
                                                <div className="item_title">{item.title}</div>
                                            </a>
                                        ) : (
                                            <Link to={item.to}>
                                                <div className={`item_nav_icon ${item.icon}`}></div>
                                                <div className="item_title">{item.title}</div>
                                            </Link>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
                {notify ? (
                    <>
                        {notify[0].isShow === true && notify[0].title === "marquee" ? (
                            <div className="header-notify">
                                <div className="container d-flex">
                                    <div className="notify-title">
                                        <i className="icon_news"></i>
                                        Thông báo
                                    </div>
                                    <marquee>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: notify[0].content
                                            }}
                                        />
                                    </marquee>
                                </div>
                            </div>
                        ) : null}
                    </>
                ) : null}
            </header>
            <PopupMenu profile={profile} isOpen={isOpen} isClose={closePopup} menuActive={menu} getProfile={getProfile} />
        </>
    );
};

export default Header;
